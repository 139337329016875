<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="resetForm()">
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Restaurant Region</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="region-form">
          <v-text-field
            label="Name"
            v-model="fields.name"
            ref="regionName"
            type="text"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="region-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      region: {},
      fields: {
        name: "",
      },
      errors: {},
    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.$nextTick().then(() => {
          this.$refs.regionName.focus();
        });
      }
    },
  },

  methods: {
    openForm: function (region = null) {
      if (region !== null) {
        this.isEditing = true;
        this.fields.name = region.name;
        this.region = region;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = "";
      this.region = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.restaurantRegionId = this.region.id;
      }

      this.$store
        .dispatch("drum/admin/saveRestaurantRegion", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>